import React, { useState } from 'react';
import './LoginPage.css';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { CircularProgress, Snackbar, Alert } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [loginError, setLoginError] = useState('');
    const [loading, setLoading] = useState(false); // Loading state for button
    const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar visibility
    const [snackbarMessage, setSnackbarMessage] = useState(''); // Snackbar message
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Snackbar severity ('success' or 'error')
    const navigate = useNavigate();

    const handleEmailChange = (e) => {
        const emailInput = e.target.value;
        setEmail(emailInput);

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(emailInput) && emailInput) {
            setEmailError('Invalid email format');
        } else {
            setEmailError('');
        }
    };

    const handlePasswordChange = (e) => setPassword(e.target.value);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!emailError) {
            setLoading(true); // Start loading when login starts
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ email, password })
                });

                if (response.status === 403) {
                    setLoginError('Incorrect username or password');
                    setSnackbarMessage('Incorrect username or password');
                    setSnackbarSeverity('error');
                    setOpenSnackbar(true);
                } else if (response.ok) {
                    const data = await response.json();
                    localStorage.setItem('token', data.jwt);
                    localStorage.setItem('username', data.username);
                    localStorage.setItem('email', data.email);

                    // Set a timeout for automatic logout after 6 hours
                    setTimeout(() => {
                        localStorage.setItem('username', data.username);
                        localStorage.setItem('email', data.email);
                        localStorage.removeItem('token');
                        toast.error('Your session has expired. Please log in again.', {
                            autoClose: false,
                            closeOnClick: false,
                            draggable: false,
                            className: 'custom-toast',
                        });
                        navigate('/');
                    }, 6 * 60 * 60 * 1000); // 6 hours in milliseconds

                    setSnackbarMessage('Login successful!');
                    setSnackbarSeverity('success');
                    setOpenSnackbar(true);
                    navigate('/admin');
                } else {
                    const data = await response.json();
                    setLoginError(`Login failed: ${data.message}`);
                    setSnackbarMessage(`Login failed: ${data.message}`);
                    setSnackbarSeverity('error');
                    setOpenSnackbar(true);
                }
            } catch (error) {
                setLoginError('An error occurred during login');
                setSnackbarMessage('An error occurred during login');
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
                console.error('An error occurred during login:', error);
            } finally {
                setLoading(false); // Stop loading after response
            }
        } else {
            console.log('Fix errors before submitting.');
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <div className="login-container">
            <ToastContainer />
            {loginError && (
                <div className="error-modal">
                    <div className="error-modal-content">
                        <span className="close-button" onClick={() => setLoginError('')}>&times;</span>
                        <p>{loginError}</p>
                    </div>
                </div>
            )}
            <form onSubmit={handleSubmit} className="login-form">
                <div className="login-header">
                    <img src="LogoTransparent.png" alt="Main Logo" className="main-logo" />
                    <img src="Login Page Image.png" alt="Secondary Logo" className="secondary-logo" />
                    <h1 className="login-antillia">Antillia Emergency</h1>
                    <h1 className="login-network">Network</h1>
                    <p className="login-welcome">Welcome</p>
                    <p className="login-description">Log into Antillia Emergency Network to continue to Admin Portal</p>
                </div>
                <div className="input-group">
                    <input
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        required
                        placeholder="Email Address"
                        id="placeholder-input"
                    />
                    {emailError && <div style={{ color: 'red' }}>{emailError}</div>}
                </div>
                <div className="input-group">
                    <input
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                        placeholder="Password"
                        id="placeholder-input"
                    />
                </div>
                <div className="login-footer">
                    <a href="#">Forgot password?</a>
                </div>
                <button
                    type="submit"
                    className="login-button"
                    disabled={loading} // Disable button while loading
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#005B5B',
                        color: 'white',
                    }}
                >
                    {loading ? <CircularProgress size={24} color="inherit" /> : 'LOGIN'}
                </button>
            </form>

            {/* Snackbar for notifications */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                    variant="filled"
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default LoginPage;
